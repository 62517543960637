import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";
import BaseUserCourse from "./BaseUserCourse.controller";
import CourseLeadSourceEnum from "@/outscal-commons-frontend/Enums/CourseLeadSourceEnum";
import { toast } from "react-toastify";
import ClientAppManager from "../Services/ClientAppManager";

class DefaultUserCourse extends BaseUserCourse {
  constructor(course, user, courseFromStrapi) {
    super(course, user, courseFromStrapi);
    this.statusUpdateMapping = {};
  }

  getButtonText = (permissions, currentStatus, currentScholarshipStatus) => {
    let text = "";
    if (
      this.courseFromStrapi?.courseType == "PAID-PROJECT" &&
      !permissions?.length
    ) {
      return "Subscribe Now";
    }
    if (permissions && permissions.length > 0) {
      text = "Continue";
    } else if (
      currentScholarshipStatus != null &&
      currentScholarshipStatus >= 0
    ) {
      text = "Scholarship Waitlist";
    } else if (currentStatus != null && currentStatus >= 0) {
      text = "Purchase";
    } else {
      text = "Enroll Now";
      if (
        this.courseFromStrapi?.amountData &&
        this.courseFromStrapi.amountData["CONTENT"]?.amount == 0
      ) {
        text = "Enroll For Free";
      }
    }
    return text;
  };

  getButtonAction = (
    permissions,
    currentStatus,
    user,
    currentScholarshipStatus
  ) => {
    if (
      this.courseFromStrapi?.courseType == "PAID-PROJECT" &&
      !permissions?.length
    ) {
      return {
        name: "scroll-pricing",
      };
    }
    if (!user?.userDetails) {
      return {
        name: "logout",
        params: {
          waitlistSource: CourseLeadSourceEnum.getValue("join-waitlist"),
        },
      };
    } else {
      let action = {};
      if (permissions && permissions.length > 0) {
        action = {
          name: "navigate",
          params: { link: this.course.previewLink },
        };
      } else if (
        currentScholarshipStatus != null &&
        currentScholarshipStatus >= 0
      ) {
        action = {};
      } else if (currentStatus != null && currentStatus >= 0) {
        action = { name: "scroll-pricing" };
      } else {
        action = {
          name: "update-status",
          params: {
            courseSlug: this.course.slug,
            waitlistSource: CourseLeadSourceEnum.getValue("join-waitlist"),
          },
        };
      }
      return action;
    }
  };

  getPricingButtonText = (
    permissions = [],
    paymentType = "CONTENT",
    beforePurchaseText,
    afterPurchaseText,
    beforePurchaseProText
  ) => {
    let currentPermissions = this.getCurrentPermissions(permissions);
    let text = "";
    if (currentPermissions.includes(paymentType)) {
      text = afterPurchaseText;
    } else {
      text = beforePurchaseText;
    }
    return text;
  };

  getPricingButtonAction(permissions = [], paymentType = "CONTENT", user) {
    if (!user?.userDetails) {
      return {
        name: "logout",
        params: {
          waitlistSource: CourseLeadSourceEnum.getValue("pricing-section"),
        },
      };
    } else {
      let action = {};
      let currentPermissions = this.getCurrentPermissions(permissions);
      if (currentPermissions.includes(paymentType)) {
        action = { name: "scroll-to-top" };
      } else {
        action = { name: "check-make-payment", params: {} };
        if (
          paymentType == "CONTENT" &&
          this.courseFromStrapi?.amountData["CONTENT"]?.amount == 0
        ) {
          action = { name: "scroll-to-top" };
        }
      }
      return action;
    }
  }
}

export default DefaultUserCourse;
